//- Accordion
//- ----------------------------------------------

.accordion {
	&-item {
		padding-bottom: 8px;
		border-bottom: 1px solid $input-border-color;

		&__body {
			padding-bottom: 12px;

			@media (min-width: $xxl-breakpoint) {
				padding-top: 4px;
				padding-bottom: 21px;
			}
		}

		&__header {
			line-height: 1.2;
			padding-bottom: 16px;

			@media (min-width: $xxl-breakpoint) {
				padding-bottom: 20px;
			}
		}

		&__link {
			position: relative;
			padding: 0 26px 0 0;
			border: none;
			background-color: transparent;
			font-weight: 700;
			letter-spacing: -.03em;
			text-align: left;
			text-transform: uppercase;

			@media (min-width: $xxl-breakpoint) {
				padding-right: 34px;
			}

			body:not(.cursor-is--active) & {
				cursor: pointer;
			}

			&:focus {
				outline: none;
			}
		}

		&__icon {
			position: absolute;
			top: 0;
			right: 0;
			width: 14px;
			height: 14px;
			border: 2px solid $primary;
			transition: border-color 300ms ease;

			@media (min-width: $xxl-breakpoint) {
				top: 3px;
				width: 16px;
				height: 16px;
			}

			&:after,
			&:before {
				position: absolute;
				top: 4px;
				left: 2px;
				width: 6px;
				height: 2px;
				content: "";
				background-color: $body-color;
				background-color: $primary;
				transition: all 300ms ease;

				@media (min-width: $xxl-breakpoint) {
					top: 5px;
					width: 8px;
				}
			}

			&:after {
				transform: rotate(-90deg);
				opacity: 0;
			}

			.accordion-item__link:hover & {
				border-color: $primary;

				&:after,
				&:before {
					background-color: $primary;;
				}
			}

			.collapsed & {
				border-color: $body-color;

				&:after,
				&:before {
					background-color: $body-color;
				}

				&:after {
					opacity: 1;
				}
			}
		}

		& + & {
			margin-top: 25px;

			@media (min-width: $xxl-breakpoint) {
				margin-top: 29px;
			}
		}
	}
}
