//- Social Menu
//- ----------------------------------------------

.social-menu {
	@include list-unstyled;

	li {
		display: inline-block;

		.partner &,
		.staff-member & {
			display: block;
			padding: 0;
			font-size: .625rem;

			@include media-breakpoint-up(lg) {
				@include fluid-value(font-size, 10);
			}

			@media (min-width: $xxl-breakpoint) {
				@include fluid-value(font-size, 14);
			}
		}

		a {
			.partner &,
			.staff-member & {
				@include media-breakpoint-up(lg) {
					@include fluid-value(width, 40);
					@include fluid-value(height, 40);
					@include fluid-value(line-height, 40);
				}
			}
		}
	}

	a:before {
		@extend %fa-icon;
		@extend .fab;
	}

	&.social-menu--header {
		a:before {
			font-size: 1.5rem;

			@media (min-width: $xxl-breakpoint) {
				font-size: 1.875rem;
			}
		}
	}

	.partner &,
	.staff-member & {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
	}
}

//- Social Menu: Header
//- ----------------------------------------------

.social-menu {
	&.social-menu--header {
		display: block;
		width: 260px;
		margin: 0;
		float: left;
		position: absolute;
		left: -124px;
		z-index: 99999;
		opacity: 0;
		visibility: hidden;
		transition: all 0s ease-in-out;

		@media (min-width: $xxl-breakpoint) {
			width: 365px;
			left: -165px;
		}

		.site-header--top & {
			top: 100%;
			transform: translateY(-1rem);
		}

		.site-header--bottom & {
			bottom: 100%;
			transform: translateY(1rem);
		}

		li {
			display: block;

			.site-header--top & {
				border-top: 2px solid transparent;

				@media (min-width: $xxl-breakpoint) {
					border-top-width: 4px;
				}
			}

			.site-header--bottom & {
				border-bottom: 2px solid transparent;

				@media (min-width: $xxl-breakpoint) {
					border-bottom-width: 4px;
				}
			}

			a {
				position: relative;
				display: block;
				padding: 23px 18px 19px;
				color: $light;
				background-color: $header-bg-color;
				border-left-width: 72px;
				border-left-style: solid;
				font-size: 1rem;
				font-weight: $nav-font-weight;
				letter-spacing: -.03em;
				line-height: 1.25;
				text-transform: uppercase;
				transition: all 240ms ease;

				@media (min-width: $xxl-breakpoint) {
					padding: 35px 28px 32px;
					border-left-width: 100px;
					font-size: 1.125rem;
				}

				&:hover {
					color: $primary;
				}

				&:before {
					position: absolute;
					top: 36px;
					left: -36px;
					transform: translateX(-50%) translateY(-50%);
					color: $light;

					@media (min-width: $xxl-breakpoint) {
						top: 50px;
						left: -50px;
					}
				}

				.link-subtitle {
					display: block;
					font-size: .625em;
					line-height: 1;
					color: $tiny-color;
				}
			}
		}
	}

	.header-social-toggle:hover & {
		visibility: visible;
		opacity: 1;
		transform: translate(0);
		transition-duration: .3s;
	}
}

//- Social Menu: Default
//- ----------------------------------------------

.social-menu--default {
	margin-bottom: 0;

	.player-info-detail__label + & {
		margin-top: 1px;
	}

	.info-box + & {
		margin-top: 20px;
	}

	li {
		display: inline-block;

		a {
			&:before {
				color: $primary;
			}

			.menu-panel & {

				@media (min-width: $xxl-breakpoint) {
					font-size: 20px;
				}
			}

			&:hover {
				opacity: .8;
			}
		}

		& + li {
			margin-left: 20px;

			@media (min-width: $xxl-breakpoint) {
				margin-left: 50px;

				.player-info-page & {
					margin-left: 40px;
				}
			}

			.menu-panel & {
				margin-left: 24px;

				@media (min-width: $xxl-breakpoint) {
					margin-left: 40px;
				}
			}
		}
	}
}

//- Social Menu: Landing
//- ----------------------------------------------

.social-menu--landing {
	display: inline-block;
	margin: -1.125rem 0 0;

	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: center;
	}

	@include media-breakpoint-down(sm) {
		text-align: left;
	}

	.site-layout--landing & {
		opacity: 1;
		transition: .7s .6s all cubic-bezier(0.86, 0, 0.07, 1);
	}

	.preloader-is--active .site-layout--landing & {
		opacity: 0;
		transform: translateY(-50%) scale(1.5);
	}

	li {
		margin: 1.125rem 3rem;

		@include media-breakpoint-down(sm) {
			display: block;
			margin: 2.25rem 0;
		}

		@include media-breakpoint-up(md) {
			margin-left: 1.5rem;
			margin-right: 1.5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-left: 3rem;
			margin-right: 3rem;
		}

		a {
			position: relative;
			display: block;
			padding-left: 2.875rem;
			font-size: 1.625rem;
			font-weight: 700;
			letter-spacing: -.03em;
			line-height: 1;
			text-transform: uppercase;
			text-align: left;
			color: $light;

			&:before {
				display: none;
			}

			i {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				font-size: 2.25rem;
				font-weight: 400;
				color: $primary;
			}
		}

	}

	.link-subtitle {
		display: block;
		font-size: .75rem;
		font-weight: 500;
	}
}

// Glitch effect on hover
.social-menu--landing-glitch {

	.glitch-layer {
		position: absolute;
		left: 0;
		top: 0;
		opacity: .8;
	}
	.glitch-layer--1 {
		color: #f0f;
		z-index: -2;
	}
	.glitch-layer--2 {
		color: #004251;
		z-index: -1;
	}

	li {

		a {
			transform: translate3d(0,0,0);

			&:hover {

				.glitch-layer--1 {
					animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both infinite;
				}
				.glitch-layer--2 {
					animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both infinite;
				}
			}
		}
	}
}

@keyframes glitch {
	0% {
			transform: translate(0)
	}
	20% {
			transform: translate(-3px, 3px)
	}
	40% {
			transform: translate(-3px, -3px)
	}
	60% {
			transform: translate(3px, 3px)
	}
	80% {
			transform: translate(3px, -3px)
	}
	to {
			transform: translate(0)
	}
}

//- Social Menu: Links
//- ----------------------------------------------

.social-menu--links {
	margin: -4px;

	li {
		padding: 4px;

		a {
			display: block;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			color: $light;

			&:hover {
				opacity: .75;
			}
		}
	}
}

//- Social Menu: Mobile Bar
//- ----------------------------------------------

.social-menu--mobile-bar {
	li {
		display: block;

		& + li {
			border-top: 1px solid $nav-sub-border-color;
		}
	}

	a {
		display: block;
		padding: 12px 54px 12px 34px;

		&:before {
			margin-right: 12px;
		}

		span {
			font-size: .75rem;
			font-family: $font-family-base;
			font-weight: $font-weight-bold;
			letter-spacing: -.03em;
			text-transform: uppercase;
			vertical-align: bottom;
			color: $light;
		}
	}
}

//- Social Icons
//- ----------------------------------------------

.social-menu,
.post__sharing {
	a[href*="discordapp.com"]::before,
	a[href*="tcl.gg/discord"]::before,
	a[href*="discord.gg"]::before { content: fa-content($fa-var-discord); }
	a[href*="dribbble.com"]::before { content: fa-content($fa-var-dribbble); }
	a[href*="facebook.com"]::before { content: fa-content($fa-var-facebook); }
	a[href*="instagram.com"]::before { content: fa-content($fa-var-instagram); }
	a[href*="linkedin.com"]::before { content: fa-content($fa-var-linkedin); }
	a[href*="medium.com"]::before { content: fa-content($fa-var-medium); }
	a[href*="mixer.com"]::before { content: fa-content($fa-var-mixer); }
	a[href*="ok.ru"]::before { content: fa-content($fa-var-odnoklassniki); }
	a[href*="pinterest."]::before { content: fa-content($fa-var-pinterest); }
	a[href*="quora.com"]::before { content: fa-content($fa-var-quora); }
	a[href*="reddit.com"]::before { content: fa-content($fa-var-reddit-alien); }
	a[href*="skype.com"]::before { content: fa-content($fa-var-skype); }
	a[href*="snapchat.com"]::before { content: fa-content($fa-var-snapchat-ghost); }
	a[href*="soundcloud.com"]::before { content: fa-content($fa-var-soundcloud); }
	a[href*="steampowered.com"]::before { content: fa-content($fa-var-steam); }
	a[href*="t.me"]::before { content: fa-content($fa-var-telegram-plane); }
	a[href*="tumblr.com"]::before { content: fa-content($fa-var-tumblr); }
	a[href*="twitter.com"]::before{ content: fa-content($fa-var-twitter); }
	a[href*="twitch.tv"]::before { content: fa-content($fa-var-twitch); }
	a[href*="vimeo.com"]::before { content: fa-content($fa-var-vimeo); }
	a[href*="vk.com"]::before { content: fa-content($fa-var-vk); }
	a[href*="wechat.com"]::before,
	a[href*="wx.qq.com"]::before { content: fa-content($fa-var-weixin); }
	a[href*="weibo.com"]::before { content: fa-content($fa-var-weibo); }
	a[href*="whatsapp.com"]::before { content: fa-content($fa-var-whatsapp); }
	a[href*="xing.com"]::before { content: fa-content($fa-var-xing); }
	a[href*="youtube.com"]::before { content: fa-content($fa-var-youtube); }
}

.post__sharing,
.social-menu.social-menu--links {
	a[href*="discordapp.com"],
	a[href*="tcl.gg/discord"],
	a[href*="discord.gg"] { background-color: $social-discordapp; }
	a[href*="dribbble.com"] { background-color: $social-dribbble; }
	a[href*="facebook.com"] { background-color: $social-facebook; }
	a[href*="instagram.com"] { background-color: $social-instagram; }
	a[href*="linkedin.com"] { background-color: $social-linkedin; }
	a[href*="medium.com"] { background-color: $social-medium; }
	a[href*="mixer.com"] { background-color: $social-mixer; }
	a[href*="ok.ru"] { background-color: $social-ok; }
	a[href*="pinterest."] { background-color: $social-pinterest; }
	a[href*="quora.com"] { background-color: $social-quora; }
	a[href*="reddit.com"] { background-color: $social-reddit; }
	a[href*="skype.com"] { background-color: $social-skype; }
	a[href*="snapchat.com"] { background-color: $social-snapchat; }
	a[href*="soundcloud.com"] { background-color: $social-soundcloud; }
	a[href*="steampowered.com"] { background-color: $social-steampowered; }
	a[href*="t.me"] { background-color: $social-telegram; }
	a[href*="tumblr.com"] { background-color: $social-tumblr; }
	a[href*="twitter.com"] { background-color: $social-twitter; }
	a[href*="twitch.tv"] { background-color: $social-twitch; }
	a[href*="vimeo.com"] { background-color: $social-vimeo; }
	a[href*="vk.com"] { background-color: $social-vk; }
	a[href*="wechat.com"],
	a[href*="wx.qq.com"] { background-color: $social-wechat; }
	a[href*="weibo.com"] { background-color: $social-weibo; }
	a[href*="whatsapp.com"] { background-color: $social-whatsapp; }
	a[href*="xing.com"] { background-color: $social-xing; }
	a[href*="youtube.com"] { background-color: $social-youtube; }
}

.social-menu.social-menu--mobile-bar {
	a[href*="discordapp.com"]::before,
	a[href*="tcl.gg/discord"]::before,
	a[href*="discord.gg"]::before { color: $social-discordapp; }
	a[href*="dribbble.com"]::before { color: $social-dribbble; }
	a[href*="facebook.com"]::before { color: $social-facebook; }
	a[href*="instagram.com"]::before { color: $social-instagram; }
	a[href*="linkedin.com"]::before { color: $social-linkedin; }
	a[href*="medium.com"]::before { color: $social-medium; }
	a[href*="mixer.com"]::before { color: $social-mixer; }
	a[href*="ok.ru"]::before { color: $social-ok; }
	a[href*="pinterest."]::before { color: $social-pinterest; }
	a[href*="quora.com"]::before { color: $social-quora; }
	a[href*="reddit.com"]::before { color: $social-reddit; }
	a[href*="skype.com"]::before { color: $social-skype; }
	a[href*="snapchat.com"]::before { color: $social-snapchat; }
	a[href*="soundcloud.com"]::before { color: $social-soundcloud; }
	a[href*="steampowered.com"]::before { color: $social-steampowered; }
	a[href*="t.me"]::before { color: $social-telegram; }
	a[href*="tumblr.com"]::before { color: $social-tumblr; }
	a[href*="twitter.com"]::before { color: $social-twitter; }
	a[href*="twitch.tv"]::before { color: $social-twitch; }
	a[href*="vimeo.com"]::before { color: $social-vimeo; }
	a[href*="vk.com"]::before { color: $social-vk; }
	a[href*="wechat.com"]::before,
	a[href*="wx.qq.com"]::before { color: $social-wechat; }
	a[href*="weibo.com"]::before { color: $social-weibo; }
	a[href*="whatsapp.com"]::before { color: $social-whatsapp; }
	a[href*="xing.com"]::before { color: $social-xing; }
	a[href*="youtube.com"]::before { color: $social-youtube; }
}

.social-menu.social-menu--header {
	a[href*="discordapp.com"],
	a[href*="tcl.gg/discord"],
	a[href*="discord.gg"] { border-color: $social-discordapp; }
	a[href*="dribbble.com"] { border-color: $social-dribbble; }
	a[href*="facebook.com"] { border-color: $social-facebook; }
	a[href*="instagram.com"] { border-color: $social-instagram; }
	a[href*="linkedin.com"] { border-color: $social-linkedin; }
	a[href*="medium.com"] { border-color: $social-medium; }
	a[href*="mixer.com"] { border-color: $social-mixer; }
	a[href*="ok.ru"] { border-color: $social-ok; }
	a[href*="pinterest."] { border-color: $social-pinterest; }
	a[href*="quora.com"] { border-color: $social-quora; }
	a[href*="reddit.com"] { border-color: $social-reddit; }
	a[href*="skype.com"] { border-color: $social-skype; }
	a[href*="snapchat.com"] { border-color: $social-snapchat; }
	a[href*="soundcloud.com"] { border-color: $social-soundcloud; }
	a[href*="steampowered.com"] { border-color: $social-steampowered; }
	a[href*="t.me"] { border-color: $social-telegram; }
	a[href*="tumblr.com"] { border-color: $social-tumblr; }
	a[href*="twitter.com"] { border-color: $social-twitter; }
	a[href*="twitch.tv"] { border-color: $social-twitch; }
	a[href*="vimeo.com"] { border-color: $social-vimeo; }
	a[href*="vk.com"] { border-color: $social-vk; }
	a[href*="wechat.com"],
	a[href*="wx.qq.com"] { border-color: $social-wechat; }
	a[href*="weibo.com"] { border-color: $social-weibo; }
	a[href*="whatsapp.com"] { border-color: $social-whatsapp; }
	a[href*="xing.com"] { border-color: $social-xing; }
	a[href*="youtube.com"] { border-color: $social-youtube; }
}
